import React from "react"

import {  NavLink, useNavigate } from 'react-router-dom';
import HeaderImage from "./HeaderImage";

const Success = (props) => {
    const navigate = useNavigate();
    return (
        <>     
      <div className="vertically-center2">
      <div className='parentDiv'>
      <div className='mssgeMainDiv'>
      <img src="/icons/message.svg"/>
      </div>
        <p className='title text-center'>Check your email</p>
        <p className='sub-title text-center'>We sent a password reset link to {props.sendEmail} click on the link to reset your password</p>
        
        <NavLink className="backsignUp mt-4" to="/Account/SignIn">
           <img src='/icons/backImgEmail.svg'/>
          <p>Back to log in</p>
        </NavLink>
      </div>
      </div>
 
            {/* <div className="row">
                <div className='col-sm-12 bg-white m-auto rounded text-center my-5 ps-2'>
                    <h1 className=' fs-1'>Success!</h1>
                    <h4 className=" mt-2 sucessMessage p-2">Password reset email is sent to <i className="text-primary"> "{props.sendEmail}" </i>  </h4>
                    <div className="pb-2">
                        <button type="submit" className="theme-btn mt-5" onClick={() => navigate('../../Account/SignIn')}>Sign In</button>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default Success;