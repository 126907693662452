import { $CommonServiceFn } from "../network/Service";
import {
  $Service_Url,
  AoAcaddemyUrl,
  AoAdminBaseUrl,
  AoIntakeBaseUrl,
  AoPartnerBaseUrl,
  CounterBaseUrl,
  CounterMobileBaseUrl,
  IntakeEntryBaseUrl,
  AoAgentBaseUrl
} from "../network/UrlPath";

import toast, { Toaster } from "react-hot-toast";

const commonLib = {
  getQueryFromUrl: function (name) {
    const params = new URLSearchParams(window.location.search);
    return params.get(name);
  },
  getSessionStorage: function (name) {
    return sessionStorage.getItem(name);
  },
  setSessionStorage: function (key, value) {
    return sessionStorage.setItem(key, value);
  },
  isEmailRegEx: function (value) {
    let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(value);
  },
  onBackButtonEvent: (e) => {
    e.preventDefault();
  },
  mobilenoconverttousformat: function (phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  },
  getSubtenantImageUrl: (subtenantId, isActive, callback) => {
    let body = {};
    body["subtenantId"] = subtenantId;
    body["isActive"] = isActive;
    $CommonServiceFn.InvokeCommonApi(
      "POST",
      $Service_Url.getSubtenantDetailPath,
      body,
      (response, error) => {
        if (response) {
          callback(response);
        } else if (error) {
          callback(null, error);
        }
      }
    );
  },

  passwordformatAndReverse: (str) => {
   
    let result = "";

    for (let i = 0; i < str.length; i++) {
      let charCode = str?.charCodeAt(i);

      if (charCode === 90 || charCode === 122) {
        charCode = charCode - 25;
      }
     else if (
        (charCode >= 65 && charCode <= 89) ||
        (charCode >= 97 && charCode <= 121)
      ) {
        charCode = charCode + 1;
      }

      result += String?.fromCharCode(charCode);
    }
    const reversedCode = result?.split('')?.reverse()?.join('');
    return reversedCode;
  },
  redirectToModule: (roleId, tokenKey) => {
    // toast.success("ACCESSING_RESPECTIVE_MODULE")

    if (roleId == 1 || roleId == 9) {
      window.location.replace(
        `${AoIntakeBaseUrl}login?token=${window.btoa(tokenKey)}`
      );
    } else if (roleId == 4 || roleId == 11) {
      window.location.replace(
        `${AoAdminBaseUrl}?token=${window.btoa(tokenKey)}`
      );
    } else if (roleId == 6) {
      window.location.replace(
        `${CounterBaseUrl}?token=${window.btoa(tokenKey)}`
      );
    } else if (roleId == 16) {
      window.location.replace(
        `${CounterMobileBaseUrl}?token=${window.btoa(tokenKey)}`
      );
    }
    else if (roleId == 21) {
      window.location.replace(
        `${IntakeEntryBaseUrl}?token=${window.btoa(tokenKey)}`
      );
    }
    else if (roleId == 5) {
      window.location.replace(
        `${AoAgentBaseUrl}?token=${window.btoa(tokenKey)}`
      );
    }
    else if (roleId == 17 || roleId == 18) {
        window.location.replace(`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`)

    }
    // else if (roleId == 3) {
    //     window.location.replace(AoIntakeBaseUrl + `?token=${window.btoa(tokenKey)}`)
    // }
    else if (
      roleId == 10 ||
      roleId == 3 ||
      roleId == 13 ||
      roleId == 14 ||
      roleId == 15
    ) {
      window.location.replace(
        AoIntakeBaseUrl + `?token=${window.btoa(tokenKey)}`
      );
    }
  },
  
  SortCountryfromArray: (array) => {
    return [...array].sort((a, b) => {
      const order = ['+1-(+1) USA','+254-(+254) KE','+91-(+91) IN'];
  
      const getFullValue = (item) => `${item.value}-${item.label}`;
  
      const aFullValue = getFullValue(a);
      const bFullValue = getFullValue(b);
  
      const aIndex = order.indexOf(aFullValue);
      const bIndex = order.indexOf(bFullValue);
  
      if (aIndex !== -1 || bIndex !== -1) {
        return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
      }
  
    });
  },

  checkPasswordCriteria: ( passwordValue, setErrorState ) => {
    const islen8 = passwordValue?.length >= 8;
    const isUpperChar = /[A-Z]/.test(passwordValue);
    const isLowerChar = /[a-z]/.test(passwordValue);
    const isNum = /\d/.test(passwordValue);
    const isSpecialChar = /[!@#$%^&*()\[\]_\-+={}<>:;",.?~'`|\\\/]/.test(passwordValue);



    

    setErrorState({ islen8, isUpperChar, isalphaNum: ((isUpperChar || isLowerChar) && isNum), isSpecialChar });
  },
};

export default commonLib;
