import React, { useEffect } from "react";
import { useState, useContext } from "react";
import Header from "../../components/Account/HeaderImage";
import Footer from "../../components/Account/Footer";
import { errorMsg, otpMedium, successMsg } from "../../components/control/Constant";
import { $CommonServiceFn } from "../../components/network/Service";
import { $Service_Url } from "../../components/network/UrlPath";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import konsole from "../../components/control/Konsole";
import { configure } from "@testing-library/react";
import commonLib from "../../components/control/commonLib";
import config from '../../config.json'
import HeaderImage from "../../components/Account/HeaderImage";

export default function Otp() {
  const navigate = useNavigate();
  const { state } = useLocation();
  konsole.log("fsvn00", state)
  const [timer, setTimer] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  // const {query} = useRouter();
  const [disabled, setDisabled] = useState(true);
  const [valid, setValid] = useState(false);
  const [error, setError] = useState("");
  const { setLoader, setcurMainImageNum, setWarning } = useContext(UserContext);
  // const locationState = useLocation().state;
  const [showModal, setShowModal] = useState(false)
  const [otpError, setOtpError] = useState('')

  let timerOn = true;

  useEffect(() => {
    if (state !== null) {
      handleQuerySubmit();
    }
  }, [state]);

  const isNotValidUndefine = (value) => {
    if (value !== undefined && value != null && value != '') {
      return true
    }
    return false
  }

  useEffect(() => {
    const hasBeenCalledBefore = sessionStorage.getItem('hasBeenCalledBefore');
    const remainingTime = sessionStorage.getItem('remainingTime');

    if (isNotValidUndefine(hasBeenCalledBefore) && isNotValidUndefine(remainingTime)) {
      timerFunction(remainingTime);
      localStorage.setItem('hasBeenCalledBefore', true);
    } else {
      timerFunction(120);
      sessionStorage.setItem('remainingTime', 120);
      sessionStorage.setItem('hasBeenCalledBefore', true);
    }

    setcurMainImageNum(2);

    return () => {
      // Perform cleanup if needed
    };
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", commonLib.onBackButtonEvent); return () => {
      window.removeEventListener("popstate", commonLib.onBackButtonEvent);
      navigate(1);
    };
  }, [])




  const handleQuerySubmit = () => {
    konsole.log("state", state);
    if (state.createId !== undefined && state.Id !== undefined && state.otpSendOn !== undefined) {
      let userLogin = {
        querySessCreateId: state.createId,
        querySessId: state.Id,
        userRegisteredId: state.userRegisteredId,
        querySessOtpSendOn: state.otpSendOn,
        querySessOtpSendMedium: state.otpSendMedium,
        signUpPlateform: state.signUpPlateform,
      };
      // localStorage.setItem("UserSignupDetail", JSON.stringify(userLogin));
      setUserDetails(userLogin);
    }
  };  

  konsole.log("jksadghjkdshg", userDetails);
  const onOtpSubmit = (gettedvalue) => {
    const submitData = {
      id: parseInt(userDetails.querySessId),
      createdBy: userDetails.querySessCreateId,
      otp: parseInt(gettedvalue),
      signUpPlateform: userDetails.signUpPlateform,
    };

    konsole.log("submitDatasubmitData", JSON.stringify(submitData), disabled)
    if (submitData.otp !== null && disabled == true) { // It should be done by backend
      setLoader(true);
      $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postOtpData, submitData, (response, errordata) => {
        setLoader(false);
        if (response) {
          konsole.log("responseOtP", response)
          getisAuthUser()
          handleErrorMessage('')
        } else if (errordata) {
          setLoader(false);
          setWarning("warning", 'Invalid Otp.')
          // handleErrorMessage("Invalid Otp.")

        }
      }
      );
    } else {
      setWarning("warning", 'OTP Expired')
      // handleErrorMessage("OTP Expired.")
    }
  };

  function timerFunction(remaining) {
    setTimer(remaining);
    sessionStorage.setItem('remainingTime', remaining);
    remaining -= 1;

    if (remaining >= 0 && timerOn) {
      setTimeout(function () {
        timerFunction(remaining);
      }, 1000);
      return;
    }
    setDisabled(false);
  }

  const handleTimer = async (e) => {
    timerFunction(120);
    document.getElementById("Otpbox").reset()
    handleErrorMessage('')
    setDisabled(true);

    const inputData = {
      createdBy: userDetails.querySessCreateId,
      otpSendOn: userDetails.querySessOtpSendOn,
      otpSendMedium: userDetails.querySessOtpSendMedium,
      id: parseInt(userDetails.querySessId),
      otpType: config.USERACTIVATION,
    };

    konsole.log("xbjhbx", inputData)

    if (inputData.createdBy !== undefined && inputData.otpSendOn !== undefined && inputData.otpSendMedium !== undefined && inputData.id !== undefined) {
      handleErrorMessage('')
      setLoader(true);
      $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postResendOtp, inputData, (response) => {
        setLoader(false);
        if (response) {
          setWarning('success', successMsg.otpSent)
          konsole.log("resendRess", response);
          userDetails.querySessId = response.data.data.id
        } else {
          setWarning('warining', errorMsg.somethingErr)
        }
      }
      );
    } else {
      setWarning('warining', errorMsg.somethingErr)
    }
  };

 
  function OtpInput() {
    if (typeof window !== "undefined") {
      const inputs = document.querySelectorAll("#otp > div > *[id]");
  
      konsole.log("fnskjv", inputs)
      for (let i = 0; i < inputs.length; i++) {
        const parentElement = document.getElementById(`${inputs[i].id}-box`);
        inputs[i].addEventListener("input", function (event) {
          const inputElement = inputs[i];
          const enteredChar = event.data;
  
          if (!/^\d*$/.test(enteredChar)) {
            inputElement.value = "";
            return;
          }
  
          inputElement.value = enteredChar;
          parentElement.classList.add("outlineInput");
  
          if (i !== inputs.length - 1 && enteredChar !== null) {
            inputs[i + 1].focus();
          }
  
          handleErrorMessage('');
        });
  
        inputs[i].addEventListener("keydown", function (event) {
          if ((event.code === "Backspace" || event.key === "Backspace") && i !== 0 && inputs[i].value == "") {
            event.preventDefault();
  
            // Clear the current input after a short delay
            setTimeout(() => {
              // inputs[i].value = "";
              parentElement.classList.remove("outlineInput");
  
              // Move focus to the previous input
              inputs[i - 1].focus();
  
              // Trigger input event for certain mobile browsers
              const inputEvent = new Event("input", { bubbles: true });
              inputs[i - 1].dispatchEvent(inputEvent);
            }, 10);
          }
        });
      }
    }
  }
  
  
  
  OtpInput();

  function onHandle(event) {
    const inputs = document.querySelectorAll("#otp > div > *[id]");
    let compiledOtp = "";
    for (let i = 0; i < inputs.length; i++) {
      compiledOtp += inputs[i].value;
    }

    let gettedvalue = (document.getElementById("otp").value = compiledOtp);
    konsole.log("fjhjg", compiledOtp, gettedvalue)
    if (gettedvalue == " " || gettedvalue.length < 6) {
      // handleErrorMessage('Invalid Otp.')
      handleErrorMessage(errorMsg.otp.invalid)
    } else {
      konsole.log(gettedvalue);
      handleErrorMessage('')
      onOtpSubmit(gettedvalue);
      return true;
    }
  }

  const handleErrorMessage = (msg) => {
    setOtpError(msg)
  }

  const getisAuthUser = () => {
    setLoader(true);
    let userName = state?.senddata.emailId ? state?.senddata.emailId : state?.senddata?.primaryEmailId
    konsole.log("abababqbab")
    $CommonServiceFn.InvokeCommonApi("GET", $Service_Url.getIsAuthUser + `${userName}`, "", async (response, error) => {
      setLoader(false);
      if (response) {
        if (response.data.data == true) {
          postActiveUserafterotp()
        } else if (response.data.data == false) {
          const { subtenantId } = state;
          if (userDetails.signUpPlateform !== 1 && userDetails.signUpPlateform !== 12) {
            navigate("/Account/setPassword", {
              state: {
                signUpPlatform: userDetails.signUpPlateform,
                nature: "NewUser",
                otpId: parseInt(userDetails.querySessId),
              },
            });
          } else {
            if (state.typeOfPage === 'startFreeTrial' && subtenantId == 3) {
              const { emailId, mobileNumber, firstName, lastName, promoCode } = state.senddata

              const promoCodeVal=(promoCode==undefined || promoCode ==null || promoCode=='') ? 'free academy user':promoCode
              let JsonObj = {
                email: emailId,
                phone: mobileNumber,
                firstName: firstName,
                lastName: lastName,
                name: firstName + ' ' + lastName,
                // address1: '',
                // city: '',
                // state: '',
                // country: '',
                // postalCode: '',
                // companyName: '',
                // website: '',
                tags: [promoCodeVal],
                // source: '',
                // customField: { "_custom_field_id_": '' }
              };
              
              const rResposne = await signUpLeadStackApi(JsonObj)
              setWarning('success', 'Congratulations! your account has been created successfully')
              navigate("/account/congratulation");
              konsole.log('rResposne', subtenantId, JsonObj)
            }
            let input = {
              userRegstrtnId: userDetails.userRegisteredId || state?.senddata?.userRegstrtnId || state?.userRegisteredId,
              otpId: parseInt(userDetails.querySessId),
              userId: userDetails.querySessCreateId,
              signUpPlatform: parseInt(userDetails.signUpPlateform),
              clientIPAddress: "::1",
            };
            konsole.log("konsole user", JSON.stringify(input));
            setLoader(true);
            $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postActiveUser, input, (response, errorData) => {
              setLoader(false);
              if (response) {
                konsole.log("success active user", response);
                if (response.data.data !== null) {
                  setWarning('success', 'Congratulations! your account has been created successfully')
                  navigate("/account/congratulation");
                }
              } else if (errorData) {
                handleErrorMessage('')
                setLoader(false);
                konsole.log("err", errorData);
              }
            }
            );
          }
        }
      } else {
        konsole.log("error", error)
        setLoader(false)
        setWarning('warining', errorMsg.somethingErr)
      }
    })
  }

  const signUpLeadStackApi = (jsonObj) => {
    return new Promise((resolve, reject) => {
      $CommonServiceFn.InvokeCommonApiForLeadStack(jsonObj, (res, err) => {
        if (res) {
          konsole.log('InvokeCommonApiForLeadStack', res)
          resolve('resolve')
        } else {
          resolve('reject')
          konsole.log('InvokeCommonApiForLeadStack', err)

        }
      })
    })
  }

  const postActiveUserafterotp = () => {
    setLoader(true)

    let input = {
      userRegstrtnId: state?.senddata?.userRegstrtnId || state?.userRegisteredId,
      otpId: parseInt(userDetails.querySessId),
      userId: userDetails.querySessCreateId,
      signUpPlatform: parseInt(userDetails.signUpPlateform),
      clientIPAddress: "::1",
      activationKey: state?.senddata?.activationKey
      // activatedBy:state?.senddata?.createdBy
    };
    if (userDetails?.signUpPlateform !== 1) {
      input.userActivationId = parseInt(state?.senddata?.userActivationId)
    }

    konsole.log("inputinputinputinput", JSON.stringify(input))
    $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postActiveUser, input, (response, errorData) => {
      setLoader(false);
      if (response) {
        konsole.log("success active user", response);
        if (response.data.data !== null) {
          setWarning('success', 'Congratulations! your account is activated successfully');
          navigate("/account/congratulation", {
            state: {
              // message:"Congratulations user activated successfully. It seems that you are already associated with us, due to which we haven't prompt to enter password."
              message: "Congratulations your account is activated successfully. It seems that you are already associated with us. You can sign in with the Old password or Reset the password using the Reset password below Sign in"
            }
          }
          );
        }
      } else if (errorData) {
        handleErrorMessage('')
        setLoader(false);
        konsole.log("err", errorData);
      }
    }
    );
  }


  let mobilenocountrycode = "";
  let mobilenowithoutcountrycode = "";

  let primaryMobile = state?.senddata.phoneNo ? state?.senddata.phoneNo : state?.senddata.primaryPhoneNumber
  if (primaryMobile !== null && primaryMobile !== undefined) {
    let sPhoneNumber = primaryMobile?.split("")
    let removeCountryCode = sPhoneNumber.splice(sPhoneNumber.length - 10, 10).join("")
    mobilenocountrycode = sPhoneNumber.join("")
    mobilenowithoutcountrycode = commonLib.mobilenoconverttousformat(removeCountryCode)
  } else {
    mobilenocountrycode = ""
    mobilenowithoutcountrycode = "Cell number is not available"
  }





  const key = () => { };
  return (
    <>
     <div id='Otp'>
      <div className='headerImg'>
        <HeaderImage />
      </div>

      <form id="Otpbox" className="vertically-center7">
      <div className="parentDiv">
      <div className='mssgeMainDiv'>
      <img src="/icons/message.svg"/>
      </div>
        <p className='title text-center'>Check your email</p>
        {konsole.log(state?.senddata,"state?.senddata")}
        <p className='sub-title text-center'>We sent a verification code to {state?.senddata?.emailId ? (state?.senddata?.emailId == null || state?.senddata?.emailId == undefined ? "Email is not available" : state?.senddata.emailId) : (state?.senddata?.primaryEmailId == undefined || state?.senddata?.primaryEmailId == null ? "Email is not available" : state?.senddata?.primaryEmailId)} and {`${mobilenocountrycode} ${mobilenowithoutcountrycode}`}</p>
        <div className='label-input'>
          <label>Enter Code</label>
          <div id="otp" className="d-flex justify-content-between mt-2">
            <div id="first-box" className="codeInput" placeholder="0">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="first"
                maxLength="1"
              />
            </div>
            <div id="second-box" className="codeInput">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="second"
                maxLength="1"
              />
            </div>
            <div id="third-box" className="codeInput">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="third"
                maxLength="1"
              />
            </div>
            <div className="hyphen">
              <h3>-</h3>
            </div>
            <div id="fourth-box" className="codeInput">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="fourth"
                maxLength="1"
              />
            </div>
            <div id="fifth-box" className="codeInput">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="fifth"
                maxLength="1"
              />
            </div>
            <div id="sixth-box" className="codeInput">
              <input type='text' placeholder="0"
                onKeyPress={key}
                id="sixth"
                maxLength="1"
              />
            </div>
          </div>
          {otpError ? <p className='attention mt-1'>{otpError}</p> : ""}
        </div>

        <div className="label-input">
          <button type="button"  onClick={() => onHandle()}>Verify email</button>
        </div>

        <p className="text-center fs-3 my-3 " id="timer">{timer > 0 ? timer : ""}</p>

        <div className="other-links">
          <p>Don't receive the email? 
          {/* <NavLink className="aTagElement" to="/Account/Signup#">Click to resend</NavLink> */}
          <button className="resendOtp cursor-pointer border-0 bg-white aTagElement" id="resend" disabled={disabled} onClick={() => handleTimer()}>Click to resend</button>
          </p>
        </div>
        <NavLink className="backsignUp" to="/Account/Signup#">
           <img src='/icons/backImgEmail.svg'/>
          <p>Back to singup</p>
        </NavLink>
        </div>
      </form>
    </div>
    </>
  );
}
