import React, { useEffect, useContext, useState,useMemo } from 'react'
import {auth0Config} from '../../components/auth0';
import { $CommonServiceFn } from '../../components/network/Service';
import { $Service_Url, AoAdminBaseUrl, AoIntakeBaseUrl, AoPartnerBaseUrl, CounterBaseUrl } from '../../components/network/UrlPath';
// import auth0Cred from "../auth0Cred.json";
import { auth0Cred } from '../auth0Cred';
import { UserContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import SelectRoles from '../../components/Account/SelectRoles'
import commonLib from '../../components/control/commonLib';
import konsole from '../../components/control/Konsole';
import LoaderScreen from './LoaderScreen';
import Header from '../../components/Account/HeaderImage';


function Redirect() {
  const { setLoader, setLoaderText, setcurMainImageNum } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedRolesData, setSelectedRolesData] = useState(null);
  const [showSelectedRolesModal, setShowSelectedRolesModal] = useState(false);
  const navigate = useNavigate();

  const [loaderscreenmodal, setLoaderscreenmodal] = useState(true)
  const [checkingconnection, setcheckingconnection] = useState(2)
  const [validateUserId, setValidateUserId] = useState(1)
  const [validateAuth0, setValidateAuth0] = useState(1)
  const [checkingmultirole, setcheckingmultirole] = useState(1)
  const [validaetsuccessfully, setvalidatesuccessfully] = useState(1)
  const [showModalMsgType,setShowModalMsgType]=useState(null)
  const [browserName, setBrowserName] = useState('');
  const [allUserData, setAllUserData] = useState()
  const filteredAllRoles = allUserData !== undefined && allUserData !== null && allUserData !== '' && allUserData.filter(role => [3,13,14,15,6].includes(role.roleId))

  useEffect(()=>{
    setcurMainImageNum(0)
  },[])




  const isStaffLogin=useMemo(()=>{
    let isStaff=window.location.pathname?.includes('Legal-staff') ||  window.location.pathname?.includes('legal-staff');
    return isStaff;
  },[window.location.pathname]);

  useEffect(() => {

    setLoader(false);
    setLoaderText('VALID_USER')

    auth0Config().checkSession({}, function (err, authResult) {
      if (err) {
      
        detectBrowser((browserMode) => {
          setBrowserName(browserMode);
          konsole.log('browsetype', browserMode);
          getDeviceType((deviceType) => {
          if (err.error === "login_required") {
            let showModalMsgType;
          
            if (browserMode === 'Safari') {
              if (deviceType === 'desktop') {
                showModalMsgType = 1;
              } else if (deviceType === 'mobile' || deviceType === 'tablet') {
                showModalMsgType = 3;
              }
            } else if (
              browserMode === 'Chrome' || 
              browserMode === 'Edge' || 
              browserMode === 'Opera' || 
              browserMode === 'Firefox' || 
              browserMode === 'Unknown'
            ) {
              if (deviceType === 'desktop' || deviceType === 'mobile' || deviceType === 'tablet') {
                showModalMsgType = (browserMode === 'Unknown') ? 4 : 2;
              }
            }
          
            if (showModalMsgType !== undefined) {
              setShowModal(true);
              setShowModalMsgType(showModalMsgType);
              setLoaderText('Processing');
            }
          }            
        })
        })
        setLoader(false)
        setLoaderText('')
        return;
      }
      if (authResult) {
      
        setTimeout(() => {

          setcheckingconnection(3)
          setValidateUserId(2)
          konsole.log("Result", authResult);

          getDeviceType((deviceType) => {
            konsole.log('devtype', deviceType);
            
            if (deviceType) {
              setTimeout(() => {
                setValidateUserId(3)
                setValidateAuth0(2)

                detectBrowser((browserMode) => {
                  konsole.log('browsetype', browserMode);
                                                      if (browserMode) {
                    

                    setTimeout(() => {
                      setValidateAuth0(3)
                      setcheckingmultirole(2)

                      const inputData = {
                        "authId": authResult.idTokenPayload.sub.split('|')[1],
                        "appState": authResult.appState,
                        "expiresIn": authResult.expiresIn,
                        "userName": authResult.idTokenPayload.email,
                        "refereshToken": null,
                        "tokenType": authResult.tokenType,
                        "clientIP": "::1",
                        "deviceType": deviceType,
                        "browseMode": browserMode,
                        "browseType": null
                      }

                      konsole.log('inputdata', JSON.stringify(inputData))
                      commonLib.setSessionStorage("AuthToken", authResult.idToken)
                      //setLoader(true);

                      setTimeout(() => {
                        setcheckingmultirole(3)
                        setvalidatesuccessfully(2)

                        $CommonServiceFn.InvokeCommonApi("POST", $Service_Url.postAuth0UserLogin, inputData, (response, errorData) => {
                          if (response) {
                            setLoader(false);

                            // konsole.log('ressss', response.data.data?.userRoles);

                            setTimeout(() => {
                              setvalidatesuccessfully(3)
                              if (response.data?.data?.appState !== undefined && response.data.data.loggedInUserId !== undefined && response.data.data.roleId !== undefined) {
                                konsole.log("selected roles id ", response);
                                const appState = response.data.data.appState;
                                const userId = response.data.data.loggedInUserId;
                                // const loggenInId = response.data.data.userLoginId;
                                const loggenInId = response.data?.data?.logUserLoginId;
                                const selectedRolesArray = response.data.data?.userRoles?.filter((v) => v?.roleId !== 2 && v?.roleId != 7 && v?.roleId != 8);
                                setAllUserData(selectedRolesArray)
                                if (selectedRolesArray.length === 1) {
                                  const selectedRoles =selectedRolesArray[0];
                                  const roleId = selectedRoles.roleId;
                                  const findArray = selectedRolesArray.filter((data) => data.roleId !==20);
                                  const filteredRoles = selectedRolesArray.filter(role => [3,13,14,15,6].includes(role.roleId));                          
                                  const json = {
                                    selectedRoles: selectedRolesArray,
                                    auhtenicateKeys: {
                                      appState: appState,
                                      userId: userId,
                                      loggenInId: loggenInId,
                                      email:response.data.data.userName,
                                      memberName:response.data.data.memberName
                                    }
                                  }
                                  if(findArray.length > 0){
                                    if(filteredRoles.length > 0){
                                      setLoaderscreenmodal(false)
                                      setcurMainImageNum(2)
                                      navigate(`/Account/SelectRoles`, {
                                        state: {
                                          selectedRolesData: json
                                        },
                                        replace: true,
                                      })

                                    }else{
                                      let finalRoleId = (roleId == 17 || roleId == 18) ? JSON.stringify(selectedRolesArray) : roleId;
                                      let tokenKey = `appState=${appState}&userId=${userId}&roleId=${finalRoleId}&loggenInId=${loggenInId}`;
                                      konsole.log("show maess", findArray, tokenKey); 
                                      commonLib.redirectToModule(roleId, tokenKey);
                                    }                                 
                                  }
                                  else{
                                    let userPswd=localStorage.getItem('|\/*P*/\|')
                                    let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(selectedRolesArray)}&loggenInId=${loggenInId}&Psw=${userPswd}`;
                                    // console.log("tokenKeytokenKey",tokenKey,`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`)
                                    window.location.replace(`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`);
                                  }
                                }
                                else {

                                  const rolesData = selectedRolesArray?.length > 0  ? selectedRolesArray :response.data.data?.userRoles
                                  const json = {
                                    selectedRoles: rolesData,
                                    auhtenicateKeys: {
                                      appState: appState,
                                      userId: userId,
                                      loggenInId: loggenInId,
                                      email:response.data.data.userName,
                                      memberName:response.data.data.memberName
                                    }
                                  }


                                  const findArray = selectedRolesArray.filter((data) =>data.roleId !== 20)
                                  const checkForAcadAndLpo = selectedRolesArray?.length === 2 && selectedRolesArray.some(item => item?.roleId === 17) && selectedRolesArray.some(item => item?.roleId === 9); // check array has only two roles LPO and Academy than redirect to LPO
                                  konsole.log("selectedRolesArray", selectedRolesArray,findArray)
                                  if(isStaffLogin){
                                    const filteredRoles = selectedRolesArray.filter(role => [3,13,14,15,6].includes(role.roleId));
                                 
                                    if(filteredRoles.length > 0){
                                      let userPswd=localStorage.getItem('|\/*P*/\|')
                                      let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(filteredRoles[0]?.roleId)}&loggenInId=${loggenInId}&Psw=${userPswd}&legalStaff=${true}`;
                                      window.location.replace(`${AoIntakeBaseUrl}login?token=${window.btoa(tokenKey)}`);                                 
                                    }else{
                                        return;
                                    }
                                  }
                                  else if(checkForAcadAndLpo == true){
                                    const redirectToLpo = selectedRolesArray?.filter(item => item?.roleId == 9)
                                    let userPswd=localStorage.getItem('|\/*P*/\|')
                                    let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(redirectToLpo[0]?.roleId)}&loggenInId=${loggenInId}&Psw=${userPswd}`;
                                    // console.log("tokenKeytokenKey",userPswd,tokenKey,`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`)
                                    window.location.replace(`${AoIntakeBaseUrl}login?token=${window.btoa(tokenKey)}`);
                                  }
                                  else{
                                    const filteredRoles = response.data.data?.userRoles.filter(role => [3,13,14,15,6].includes(role.roleId)); 
                                  if(findArray.length > 0 || filteredRoles.length > 0){
                                    // setSelectedRolesData(json);
                                    // setShowSelectedRolesModal(true);
                                    setLoaderscreenmodal(false)
                                    setcurMainImageNum(2)
                                    navigate(`/Account/SelectRoles`, {
                                      state: {
                                        selectedRolesData: json
                                      },
                                      replace: true,
                                    })
                                  }else{
                                    let userPswd=localStorage.getItem('|\/*P*/\|')
                                    let tokenKey = `appState=${appState}&userId=${userId}&roleId=${JSON.stringify(selectedRolesArray)}&loggenInId=${loggenInId}&Psw=${userPswd}`;
                                    // console.log("tokenKeytokenKey",userPswd,tokenKey,`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`)
                                    window.location.replace(`${AoPartnerBaseUrl}login?token=${window.btoa(tokenKey)}`);
                                  }
                                  }
                                }
                              }
                            }, 500)

                          }
                          else if (errorData) {
                            setLoader(false);
                            setLoaderText('')
                          }
                        })

                      }, 500)
                    }, 500)
                  }
                });
              }, 500)
            }
          });
        }, 300)

      } 
    });
  }, [])


  const getCountryIpCode = async (callback) => {
    // setLoader(true);
    let url = "https://geolocation-db.com/json/";
    let data = await fetch(url);
    let parsedData = await data.json();
    setLoader(false);
    konsole.log("dattaa", parsedData);
    callback(parsedData.IPv4);
  }
  const getDeviceType = (callback) => {
    const ua = navigator.userAgent;
    konsole.log('ua', ua);
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return callback("tablet");
    
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return callback("mobile");
    }
    return callback("desktop");


  };

  const detectBrowser = (callback) => {
    const userAgent = navigator.userAgent;

    if (userAgent.indexOf("Opera") !== -1 || userAgent.indexOf('OPR') !== -1) {
        return callback('Opera');
    } else if (userAgent.indexOf("Edg") !== -1) {
        return callback('Edge');
    } else if (userAgent.indexOf("Chrome") !== -1 && userAgent.indexOf("Safari") !== -1) {
        return callback('Chrome');
    } else if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
        return callback('Safari');
    } else if (userAgent.indexOf("Firefox") !== -1) {
        return callback('Firefox');
    } else if (userAgent.indexOf("MSIE") !== -1 || !!document.documentMode === true) {
        return callback('IE');
    } else {
        return callback('Unknown');
    }
  }
  const redirectTOLogin =()=>{
    navigate(`/Account/SignIn`, {
        })

  }


  return (
    <>  
    {/* <Header pagename="Redirect" /> */}
      <div className="container-fluid ">


        {/* <div className="row">
        <div className={`col-sm-12 col-md-8 col-lg-8 bg-white rounded dased-border pt-2 pb-3 mt-3 m-auto text-center my-3 ${showSelectedRolesModal ? 'd-none' : 'd-block'}`}>
          <h1 className='congratulationText text-center mt-4'>Your request is being submitted. Please do not close this window or click the Back button on your browser. We are redirecting you to requested site.</h1>
        </div>
      </div> */}
        {
         (showModal==true && showModalMsgType !==null ) &&
          <div className="alert alert-info position-fixed top-0 end-0 m-5 text-white border-0" style={{ width: '90%', maxWidth: '430px', backgroundColor: 'rgba(114,12,32)', zIndex: 99999 }}>
            {/* <span aria-hidden="true" className=" fs-3 position-absolute top-0 end-0 me-2 cursor-pointer" onClick={() =>{ setShowModal(false); setShowModalMsgType(null)}}>&times;</span> */}
          {(showModalMsgType==1) ? 
            <div className='fs-5'>
              <p className="mb-0">Your privacy policy is blocking our website to proceed.
              </p>
              <hr />
              <p>Please change the privacy setting as per the steps below.</p>
              <p>Go to Preferences {'->'} Privacy</p>
              <p>Here you will see website tracking. Please uncheck the checkbox and refresh.</p>
            </div>:(showModalMsgType==2)?<div className='fs-5'>
                <p className="mb-0">It seems that your browser's privacy settings, specifically third-party cookies, are interfering with our website's functionality.</p>
                <hr />
                <p>To resolve this issue, please adjust your privacy settings by following these steps:</p>
                <ul>
                    <li>Open <strong>{browserName}</strong> settings.</li>
                    <li>Set <strong>{browserName}</strong> as your default browser.</li>
                    <li>Go to <strong>Default App</strong> and open Settings.</li>
                    <li>Select <strong>Privacy & Security</strong>.</li>
                    <li>Ensure that the <strong>Allow Cross-Site Tracking (Third-Party Cookies)</strong> option is <strong>Allow all cookies.</strong></li>
                </ul>
            </div>:(showModalMsgType==3)?<div>
              <p className="mb-0">Your privacy policy is blocking our website to proceed. </p> <hr />
              <p>Please change the privacy setting as per the steps below.</p>
              <p>Go to Settings {'->'}</p>
              <p>Click on Safari</p>
              <p>Privacy & Security</p>
              <p>Turn the toggle 'Prevent Cross-Site Tracking' to 'Off'</p>
            </div>:(showModalMsgType==4)?<div className='fs-5'>
                <p className="mb-0">It seems that your browser's privacy settings, specifically third-party cookies, are interfering with our website's functionality.</p>
                <hr />
                <p>To resolve this issue, please adjust your privacy settings by following these steps:</p>
                <ul>
                    <li>Open browser's settings.</li>
                    <li>Select <strong>Privacy & Security</strong>.</li>
                    <li>Ensure that the <strong>Allow Cross-Site Tracking (Third-Party Cookies)</strong> option is <strong>Allow all cookies.</strong></li>
                </ul>
            </div>:""}
            
          </div>
        }

        {/* <div className="container-fluid  position-relative" style={{top:"24vh"}}>
          <div className="row">
            <div className={`${(showSelectedRolesModal) ? "center_div_contant_one_first" : "center_div_contant_one_second"} col-lg-7 border-0 `} > */}
              {(isStaffLogin==true) && filteredAllRoles.length == 0 ? <>
                <div className='desc'style={{position:"absolute",top:"40%"}}>
        <p className='title text-center'> You are not staff; try using the button below.</p>
        <div className="label-input me-2">
          <button type="submit" onClick={redirectTOLogin}>Go to the Universal Login</button>
        </div>
      </div>
              </> :<>
                <LoaderScreen checkingconnection={checkingconnection} validateUserId={validateUserId} validateAuth0={validateAuth0}
                checkingmultirole={checkingmultirole} validaetsuccessfully={validaetsuccessfully}/>
              </>}
             

            {/* </div> */}
            {/* {showSelectedRolesModal &&
              <div className={`${(showSelectedRolesModal) ? "center_div_contant_two_first" : "center_div_contant_two_second"} col-lg-5 border-0  `}>
                <SelectRoles setShowModal={setShowSelectedRolesModal} selectedRolesData={selectedRolesData} />
              </div>
            } */}
          {/* </div>
        </div> */}

      </div>
    </>
  )
}

export default Redirect;