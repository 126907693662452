import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../App";
import commonLib from "../control/commonLib";
import konsole from "../control/Konsole";




const HeaderImage = (props) => {
  const [imageUrl, setImageUrl] = useState('');
  const { setLoader } = useContext(UserContext);

  useEffect(() => {
    let subtenantId = commonLib.getQueryFromUrl("subtenantId");

    let exist = commonLib.getSessionStorage("exist");
    let existObj = false;

    const subtenantLogoUrl = JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantId;
    if (subtenantLogoUrl !== undefined && subtenantLogoUrl !== "") {
      commonLib.setSessionStorage("exist", true);
      existObj = true;
    }
    if (existObj == true && subtenantId == null) {
      const subtenantLogoUrl = JSON.parse(commonLib.getSessionStorage("subtenantDetails"))?.subtenantLogoUrl
      setImageUrl(subtenantLogoUrl)
    }
    if (existObj == true && subtenantId !== null) {
      getSubtenantImage(subtenantId)
    }
    if (existObj == false) {

      getSubtenantImage(subtenantId ?? 742)
    }
  }, [])

  const getSubtenantImage = (subtenantId = 2) => {
    setLoader(true);
    commonLib.getSubtenantImageUrl(subtenantId, true, (response, error) => {
      setLoader(false);
      if (response) {
        setLoader(false);
        let body = {
          subtenantId: response.data.data[0]?.subtenantId,
          subtenantLogoUrl: response.data.data[0]?.subtenantLogoUrl
        }
        setImageUrl(response.data.data[0].subtenantLogoUrl);
        commonLib.setSessionStorage("subtenantDetails", JSON.stringify(body))
      }
      else if (error) {
        setLoader(false);
        konsole.log("error", error);
      }
    })
  }

  return (
    <>
      {/* {(props?.pagename == "Redirect") ?
      <img src={imageUrl}
        className='Nav_header_logo'
      /> :  */}
      <div className="mb-3">
          <div className="row">
          <div className="m-auto col-4 d-flex universalHeaderOptions ps-4  justify-content-center">
      <img
        src={imageUrl}
        alt="brand logo"
        className=""
      />
      </div>
      </div>
      </div>

      {/* } */}
    </>

  );
};

export default HeaderImage;
